<template>
  <v-main>

    <v-container
        id="view-account-container"
        fluid
        tag="section"
        v-if="user"
    >

      <base-material-card
          icon="mdi-account-details-outline"
          class="px-5 py-3"
      >

        <v-card-text v-if="errors.length">
          <v-alert color="error"
                   outlined
                   dark
                   :key="error.id"
                   show variant="danger"
                   v-for="error in errors">
            {{ error }}
          </v-alert>
        </v-card-text>

        <v-row>
          <v-col
              cols="12"
              sm="3"
              class="font-weight-medium"
          >
            Account Number:
          </v-col>
          <v-col
              cols="12"
              sm="4"
          >
            {{ user.accountNumber }}
          </v-col>
        </v-row>

        <v-row>
          <v-col
              cols="12"
              sm="3"
              class="font-weight-bold"
          >
            Name:
          </v-col>
          <v-col
              cols="12"
              sm="4"
          >
            {{ user.names }}
          </v-col>
        </v-row>

        <v-row>
          <v-col
              cols="12"
              sm="3"
              class="font-weight-bold"
          >
            Address:
          </v-col>
          <v-col
              cols="12"
              sm="4"
          >
            {{ user.address }} <br />
            <span v-if="user.addressLine2"> {{ user.addressLine2 }} <br /> </span>
            {{ user.city }}, {{ user.state }} {{ user.postalCode }} <br />
            <span v-if="user.country"> {{ computedCountry.name }} </span>
          </v-col>
        </v-row>

        <v-row>
          <v-col
              cols="12"
              sm="3"
              class="font-weight-bold"
          >
            Phone Number:
          </v-col>
          <v-col
              cols="12"
              sm="4"
          >
            {{ user.phoneNumber }}
          </v-col>
        </v-row>

        <v-row>
          <v-col
              cols="12"
              sm="3"
              class="font-weight-bold"
          >
            Email Address:
          </v-col>
          <v-col
              cols="12"
              sm="4"
          >
            {{ user.email }}
          </v-col>
        </v-row>

        <v-row>
          <v-col
              cols="12"
              sm="3"
              class="font-weight-bold"
          >
            Gender:
          </v-col>
          <v-col
              cols="12"
              sm="4"
          >
            {{ user.gender }}
          </v-col>
        </v-row>

        <v-row>
          <v-col
              cols="12"
              sm="3"
              class="font-weight-bold"
          >
            Year of Birth:
          </v-col>
          <v-col
              cols="12"
              sm="4"
          >
            {{ user.birthYear }}
          </v-col>
        </v-row>

        <v-row>
          <v-col
              cols="12"
              sm="3"
              class="font-weight-bold"
          >
            ID Number:
          </v-col>
          <v-col
              cols="12"
              sm="4"
          >
            {{ user.idNumber }}
          </v-col>
        </v-row>

        <v-row>
          <v-col
              cols="12"
              sm="3"
              class="font-weight-bold"
          >
            ID Type:
          </v-col>
          <v-col
              cols="12"
              sm="4"
          >
            <span v-if="user.idType">
              {{ user.idType.name }}
            </span>
          </v-col>
        </v-row>

        <v-row>
          <v-col
              cols="12"
              sm="3"
              class="font-weight-bold"
          >
            ID Issued By:
          </v-col>
          <v-col
              cols="12"
              sm="4"
          >
            {{ user.idIssuedBy }}
          </v-col>
        </v-row>

        <v-row>
          <v-col
              cols="12"
              sm="3"
              class="font-weight-bold"
          >
            ID Expires:
          </v-col>
          <v-col
              cols="12"
              sm="4"
          >
            {{ user.idExpires }}
          </v-col>
        </v-row>

        <v-row>
          <v-col
              cols="12"
              sm="3"
              class="font-weight-bold"
          >
            User Status:
          </v-col>
          <v-col
              cols="12"
              sm="4"
          >
            {{ computedStatus }}
          </v-col>
        </v-row>

      </base-material-card>
    </v-container>

  </v-main>
</template>

<script>
import api from '@/services/api'
import geoUtils from '@/utils/geography'
import constants from "@/utils/constants";

import {mapGetters} from "vuex";

export default {
  name: 'CoreViewUserView',

  created() {
    this.userId = this.getUserId
    this.fetchUser()
  },

  computed: {
    ...mapGetters({
      getUserId: 'getUserId'
    }),
    computedStatus: {
      get() {
        return this.userStatusMap[this.user.status]
      },
      set(value) {
        this.user.status = this.userStatusMap.indexOf(value)
      }
    },
    computedCountry: {
      get() {
        return geoUtils.findCountryByCode(this.user.country)
      },
      set(value) {
        this.user.country = value
      }
    }
  },

  methods: {
    fetchUser() {
      api
          .fetchUser(this.userId)
          .then(response => {
            this.user = response.data
            this.fetchDependents()
          })
          .catch(err => {
            this.handleError(err)
          })
    },
    fetchDependents() {
      api
          .fetchDependents(this.userId)
          .then(response => {
            this.dependents = response.data
          })
          .catch(err => {
            this.handleError(err)
          })
    },
    handleError(err) {
      let errorMessage = api.parseResponseError(err)
      this.errors.push(errorMessage)
    }
  },

  data() {
    return {
      errors: [],
      userId: null,
      user: {
        country: 'US',
        idType: {}
      },
      dependents: [],
      userStatusMap: constants.getUserStatusMap()
    }
  }
}
</script>